import React, { useState } from 'react';
import WormholeBridge, { Theme, OPACITY } from '@wormhole-foundation/wormhole-connect';
// import WormholeBridge, { Theme, OPACITY, light, dark } from '@wormhole-foundation/wormhole-connect';
// import { Spin } from 'antd'
// import lightblue from '@mui/material/colors/lightBlue';
// import blue from '@mui/material/colors/blue';
import grey from '@mui/material/colors/grey';
// import green from '@mui/material/colors/green';
import orange from '@mui/material/colors/orange';
import red from '@mui/material/colors/red';
// import logo from './logo.svg';
import './App.css';
// import { log } from 'console';
import docs from "./images/default-img/docs.png"
// import docsHover from "./images/default-img/docsHover.png"
import twitter from "./images/default-img/twitter.png"
// import twitterHover from "./images/default-img/twitterHover.png"
import discord from "./images/default-img/discord.png"
// import discordHover from "./images/default-img/discordHover.png"
import group from "./images/default-img/group.png"
// import groupHover from "./images/default-img/groupHover.png"
import tgchannel from "./images/default-img/tgchannel.png"
// import tgchannelHover from "./images/default-img/tgchannelHover.png"
import medium from "./images/default-img/medium.png"
import deepbook_img from "./images/sui-img/deepbook-menu.png"
import deepbook_img_sui from "./images/sui-img/deepbook-menu-sui.png"
import deepbook_img_hover from "./images/sui-img/deepbook-menu-hover.png"
import limit_beta from "./images/sui-img/limit-beta.png"
import selectMenu from "./images/default-img/select-menu.png"
import portal from "./images/sui-img/Portal-Bridge.png"
// import mediumHover from "./images/default-img/mediumHover.png"
import { createFromIconfontCN } from '@ant-design/icons';
import { Space } from 'antd';
const sui: Theme = {
  primary: grey,
  secondary: grey,
  divider: '#ffffff' + OPACITY[20],
  background: {
    default: '#232323',
  },
  text: {
    primary: '#ffffff',
    secondary: grey[500],
  },
  error: red,
  info: grey,
  success: grey,
  warning: orange,
  button: {
    primary: '#162630',
    primaryText: '#ffffff',
    disabled: '#ffffff' + OPACITY[10],
    disabledText: '#ffffff' + OPACITY[40],
    action: '#76c8ff',
    actionText: '#000000',
    hover: '#ffffff' + OPACITY[7],
  },
  options: {
    hover: '#474747',
    select: '#202020',
  },
  card: {
    background: '#191919',
    secondary: '#202020',
    elevation: 'none',
  },
  popover: {
    background: '#162630',
    secondary: '#192623' + OPACITY[80],
    elevation: 'none',
  },
  modal: {
    background: '#1C1C1C',
  },
  font: {
    primary: '',
    header: ''
  }
};
const aptos: Theme = {
  primary: grey,
  secondary: grey,
  divider: '#ffffff' + OPACITY[20],
  background: {
    default: '#232323',
  },
  text: {
    primary: '#ffffff',
    secondary: grey[500],
  },
  error: red,
  info: grey,
  success: grey,
  warning: orange,
  button: {
    primary: '#1a2b27',
    primaryText: '#ffffff',
    disabled: '#ffffff' + OPACITY[10],
    disabledText: '#ffffff' + OPACITY[40],
    action: '#68ffd8',
    actionText: '#000000',
    hover: '#ffffff' + OPACITY[7],
  },
  options: {
    hover: '#474747',
    select: '#202020',
  },
  card: {
    background: '#191919',
    secondary: '#202020',
    elevation: 'none',
  },
  popover: {
    background: '#1a2b27',
    secondary: '#192623' + OPACITY[80],
    elevation: 'none',
  },
  modal: {
    background: '#1C1C1C',
  },
  font: {
    primary: '',
    header: ''
  }
};
const configSui: any = {
  mode: 'dark',
  env: 'mainnet',
  customTheme: sui,
  rpcs: {
    solana: 'https://rpc-public.hellomoon.io/',
  }
}
const configAptos: any = {
  mode: 'dark',
  env: 'mainnet',
  customTheme: aptos,
  rpcs: {
    solana: 'https://rpc-public.hellomoon.io/'
  }
}

const IconFont = createFromIconfontCN({
  scriptUrl: 'https://at.alicdn.com/t/c/font_3580402_4mwmi1y7lbw.js?spm=a313x.manage_type_myprojects.i1.13.29783a817PtgOl&file=font_3580402_4mwmi1y7lbw.js'
});


function App() {
  const classesChange = () => {
    const routers = window.location.pathname.toString()
    if (routers === '/sui') {
      return true
    } else {
      return false
    }
  }
  const bodyClassChange = () => {
    const routers = window.location.pathname.toString()
    // console.log(window.location)
    // console.log(routers)
    if (routers === '/sui') {
      return 'body-container-bg'
    } else {
      return 'body-container-bg body-container-bg-aptos'
    }
  }
  // let switchShow = true
  // const getChangeSwitch = () => {
  //   setTimeout(() => {
  //     let ids:any = document.getElementById("spinId");
  //     if(!ids) return
  //     ids.remove(); 
  //   }, 3000);
  // }

  // getChangeSwitch()
  const [messageShow, setMessageShow] = useState(false)
  const [earnShow, setEarnShow] = useState(false)
  const [tradeShow, setTradeShow] = useState(false)
  const handleClick = () => {
    setMessageShow(!messageShow)
    setEarnShow(false)
    setTradeShow(false)
  }
  const toPage = (url:any)=>{
    window.location.href = url
  }
  const list = [
    {
      name: "Docs",
      icon: 'docs',
      iconhover: 'docsHover',
      link: 'https://cetus-1.gitbook.io/cetus-docs/'
    },
    {
      name: "Twitter",
      icon: 'twitter',
      iconhover: 'twitterHover',
      link: 'https://twitter.com/CetusProtocol'
    },
    {
      name: "Discord",
      icon: 'discord',
      iconhover: 'discordHover',
      link: 'https://discord.gg/rQtYGfmcD8'
    },
    // {
    //   name: "TG Group",
    //   icon: 'group',
    //   iconhover: 'groupHover',
    //   link: 'https://t.me/cetuscommunity'
    // },
    {
      name: "TG Channel",
      icon: 'tgchannel',
      iconhover: 'tgchannelHover',
      link: 'https://t.me/cetusprotocol'
    },
    {
      name: "Medium",
      icon: 'medium',
      iconhover: 'mediumHover',
      link: 'https://medium.com/@CetusProtocol'
    }
  ]
  return (
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.tsx</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
    <div className={classesChange() ? 'sui' : 'aptos'}>
      {/* <div id='spinId'>
        <Spin
          spinning={true}  //是否开启加载中
          size={'large'}  //是否开启加载中
          // tip='Loading...'          //loading文本，这里也可以设置为动态的文本
          style={{
            backgroundColor: 'rgba(0,0,0)', //修改Spin原来的背景颜色
            minHeight: '100vh',                     //使其为整个页面高度
            minWidth: '100%',
            position: 'fixed',
            zIndex: '10000',
            top: '0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
        </Spin>
      </div> */}

      <div className='header'>
        <div className={classesChange() ? "headerAll sui-header" : "headerAll"}>
          {/* <Button type="primary">Button</Button> */}
          <div className='top-left-header'>
            <p className='top-logo' style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", minWidth: "132px",marginRight: "28px" }}>
              <a className="left-logo" href="https://app.cetus.zone/swap"> </a>
              <div className="left-bridge-logo"></div>
              {/* <span className="left-toothpick"></span> */}
            </p>
            <p style={{ display: "flex", alignItems: "center" }} className='menu' >
              {/* <a href="https://app.cetus.zone/swap" target="_self" rel="noreferrer">Swap</a> */}
              <div className='more_icon' onClick={()=>toPage('https://app.cetus.zone/swap')}>
              Trade
                <IconFont type="icon-icon_on" className='icon' />
                <div className="earn-list" style={{paddingBottom:"0px"}}>
                  <a href="https://app.cetus.zone/swap" className="item" target="_self" rel="noreferrer">
                      <div className='more_link'>
                        <IconFont type="icon-icon_swap" className='item-icon' />
                        <span>Swap</span>
                      </div>
                      <div className="start-item"></div>
                  </a>
                  <a href="javascript:;">
                    <div 
                      onClick={(e)=>{
                          e.stopPropagation()
                          e.nativeEvent.stopImmediatePropagation()
                        toPage('https://app.cetus.zone/limit?chain=sui')
                      }} 
                      className="item" 
                    >
                      <div className='more_link more_link_beta'>
                        <IconFont type="icon-icon_limitorder" className='item-icon' />
                        <span>Limit Order </span>
                        <img className="limit_beta" src={limit_beta} alt="" />
                      </div>
                    </div>
                  </a>
                  <a href="https://deepbook.cetus.zone" className="item" target="_self" rel="noreferrer">
                    <div className='more_link'>
                      {/* <IconFont type="icon-icon_deepbook" className='item-icon' /> */}
                      {classesChange() &&<img className="default-img" src={deepbook_img_sui} alt="" />}
                      {!classesChange() &&<img className="default-img" src={deepbook_img} alt="" />}
                      <img className="hover-img" src={deepbook_img_hover} alt="" />
                      <span>DeepBook</span>
                    </div>
                  </a>
                  <div className="bot_icon">
                  </div>
                </div>
              </div>
              <div className='more_icon'>
              Earn
                <IconFont type="icon-icon_on" className='icon' />
                <div className="earn-list" style={{paddingBottom:"0px"}}>
                  <a href="https://app.cetus.zone/pool/list" className="item" target="_self" rel="noreferrer">
                      <div className='more_link'>
                        <IconFont type="icon-icon_liquiditypools" className='item-icon' />
                        <span>Pools</span>
                      </div>
                      <div className="start-item"></div>
                  </a>
                  <a href="https://app.cetus.zone/farms" className="item" target="_self" rel="noreferrer">
                      <div className='more_link'>
                        <IconFont type="icon-icon_farms" className='item-icon' />
                        <span>Farms</span>
                      </div>
                  </a>
                  <a href="https://app.cetus.zone/vaults" className="item" target="_self" rel="noreferrer">
                      <div className='more_link'>
                        <IconFont type="icon-icon_vaults" className='item-icon' />
                        <span>Vaults</span>
                      </div>
                  </a>
                  <div className="bot_icon">
                  </div>
                </div>
              </div>
              <a href="https://app.cetus.zone/xcetus" target="_self" rel="noreferrer">xCETUS</a>
              <a href="https://launch.cetus.zone/?chain=sui" target="_self" rel="noreferrer">Launchpad</a>
              <div className='more_icon'>
                More
                <IconFont type="icon-icon_on" className='icon' />
                <div className="more-list">
                  {/* <a href="https://www.portalbridge.com/#/transfer" className="item" target="_self" rel="noreferrer">
                      <div className='more_link'>
                        <IconFont type="icon-buy-crypto" className='item-icon' />
                        <span>Buy Crypto</span>
                      </div>
                  </a> */}
                  <a href="/" className="item bridge" target="_self" rel="noreferrer">
                      <div className='more_link'>
                        <IconFont type="icon-icon-Bridge" className='item-icon' />
                        <span>Bridge</span>
                      </div>
                      <div className="start-item"></div>
                  </a>
                  <a href="https://app.cetus.zone/stats" className="item" target="_self" rel="noreferrer">
                      <div className='more_link'>
                        <IconFont type="icon-icon-Stats" className='item-icon' />
                        <span>Stats</span>
                      </div>
                  </a>
                  <a href="https://cetus-1.gitbook.io/cetus-docs" className="item" target="_self" rel="noreferrer">
                      <div className='more_link'>
                        <IconFont type="icon-icon-Docs1" className='item-icon' />
                        <span>Docs</span>
                      </div>
                  </a>
                  <div className="line"></div>
                  <div className="bot_icon">
                  {list.map((item) => (
                    <a
                      style={{ marginBottom: 8 }}
                      key={item.name}
                      href={item.link}
                      target="_blank"
                      rel="noreferrer"
                      className={item.name === 'Docs' ? 'not_show':''}
                    >
                      {/* <img
                        style={{ width: 20, height: 20, marginRight: 8 }}
                        src={item.icon === "docs" ? docs : item.icon === "twitter" ? twitter : item.icon === "discord" ? discord : item.icon === "group" ? group : item.icon === "tgchannel" ? tgchannel : medium}
                        alt="" /> */}
                      <IconFont type={
                          item.icon === "twitter" ? 'icon-svg-twitter' :
                            item.icon === "discord" ? 'icon-svg-Discord' :
                              item.icon === "group" ? group :
                                item.icon === "tgchannel" ? 'icon-svg-tg' :
                                  'icon-svg-medium'} className='item-icon' />
                    </a>
                  ))}
                  </div>
                </div>
              </div>
              
              {/* <a className='bridge' href="/">Bridge</a>
              <a href="https://app.cetus.zone/stats?source=bridge" target="_self" rel="noreferrer">Stats</a> */}
            </p>
          </div>
          <div className='select'>
            <a href="https://www.portalbridge.com/#/transfer" target="_self" rel="noreferrer">
              <img src={portal} alt="" />
              <span>Switch to Portal Bridge</span>
            </a>
            {/* <div className="spot">
              <span></span>
              <span></span>
              <span></span>
              <div className='exhibit'>
                {list.map((item) => (
                  <a
                    style={{ marginBottom: 8 }}
                    key={item.name}
                    href={item.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      style={{ width: 20, height: 20, marginRight: 8 }}
                      src={item.icon === "docs" ? docs : item.icon === "twitter" ? twitter : item.icon === "discord" ? discord : item.icon === "group" ? group : item.icon === "tgchannel" ? tgchannel : medium}
                      alt="" />
                    <span>{item.name}</span>
                  </a>
                ))}
              </div>
            </div> */}
            <div className="h5-select-modal">
              <img src={selectMenu} alt="" />
              <div className="left-modal">
                <div className="logo">
                  <div className="left-logo"></div>
                  {/* <div className="left-toothpick"></div> */}
                </div>
                <div className="menu-list exhibit">
                  {/* <a href="https://app.cetus.zone/swap" target="_self" rel="noreferrer">Swap</a> */}
                  <div className={classesChange() ? "h5_earn_icon sui-h5_earn_icon" : "h5_earn_icon"} style={{ height: tradeShow ? '170px' : '25px' }}  onClick={()=>{
                      setTradeShow(!tradeShow)
                      setMessageShow(false)
                      setEarnShow(false)
                    }}>
                    Trade
                    <IconFont type="icon-icon_on" className='icon'  style={{transform:tradeShow?'rotate(180deg)':'rotate(0)'}} />
                    <div className="h5_earn-list" style={{display:tradeShow?'block':'none' }}>
                      <div className="line"></div>
                      <a href="https://app.cetus.zone/swap" className="item" target="_self" rel="noreferrer">
                          <div className='more_link'>
                            <IconFont type="icon-icon_swap" className='item-icon' />
                            <span>Swap</span>
                          </div>
                      </a>
                      <a href="javascript:;">
                        <div 
                          onClick={(e)=>{
                            e.stopPropagation()
                            e.nativeEvent.stopImmediatePropagation()
                            toPage('https://app.cetus.zone/limit?chain=sui')
                          }} 
                          className="item"
                        >
                          <div className='more_link more_link_beta'>
                            <IconFont type="icon-icon_limitorder" className='item-icon' />
                            <span>Limit Order  </span>
                            <img className="limit_beta" src={limit_beta} alt="" />
                          </div>
                        </div>
                      </a>
                      <a href="https://deepbook.cetus.zone" className="item" target="_self" rel="noreferrer">
                          <div className='more_link'>
                            {/* <IconFont type="icon-icon_deepbook" className='item-icon' /> */}
                            {classesChange() &&<img className="default-img" src={deepbook_img_sui} alt="" />}
                            {!classesChange() &&<img className="default-img" src={deepbook_img} alt="" />}
                            <img className="hover-img" src={deepbook_img_hover} alt="" />
                            <span>DeepBook</span>
                          </div>
                      </a>
                    </div>
                  </div>

                  <div className={classesChange() ? "h5_earn_icon sui-h5_earn_icon" : "h5_earn_icon"} style={{ height: earnShow ? '170px' : '25px' }}  onClick={()=>{
                      setEarnShow(!earnShow)
                      setMessageShow(false)
                      setTradeShow(false)
                    }}>
                    Earn
                    <IconFont type="icon-icon_on" className='icon'  style={{transform:earnShow?'rotate(180deg)':'rotate(0)'}} />
                    <div className="h5_earn-list" style={{display:earnShow?'block':'none' }}>
                      <div className="line"></div>
                      <a href="https://app.cetus.zone/pool/list" className="item" target="_self" rel="noreferrer">
                          <div className='more_link'>
                            <IconFont type="icon-icon_liquiditypools" className='item-icon' />
                            <span>Pools</span>
                          </div>
                      </a>
                      <a href="https://app.cetus.zone/farms" className="item" target="_self" rel="noreferrer">
                          <div className='more_link'>
                            <IconFont type="icon-icon_farms" className='item-icon' />
                            <span>Frams</span>
                          </div>
                      </a>
                      <a href="https://app.cetus.zone/vaults" className="item" target="_self" rel="noreferrer">
                          <div className='more_link'>
                            <IconFont type="icon-icon_vaults" className='item-icon' />
                            <span>Vaults</span>
                          </div>
                      </a>
                    </div>
                  </div>

                  <a href="https://app.cetus.zone/xcetus" target="_self" rel="noreferrer">xCETUS</a>
                  <a href="https://launch.cetus.zone/?chain=sui" target="_self" rel="noreferrer">Launchpad</a>
                  <div className="h5_more_icon" onClick={()=>handleClick()}>
                    More
                    <IconFont type="icon-icon_on" className='icon'  style={{transform:messageShow?'rotate(180deg)':'rotate(0)'}} />
                    <div className="h5_more-list" style={{display:messageShow?'block':'none'}}>
                      <div className="line"></div>
                      <a href="/" className="item bridge" target="_self" rel="noreferrer">
                          <div className='more_link'>
                            <IconFont type="icon-icon-Bridge" className='item-icon' />
                            <span>Bridge</span>
                          </div>
                          <div className="start-item"></div>
                      </a>
                      <a href="https://app.cetus.zone/stats" className="item" target="_self" rel="noreferrer">
                          <div className='more_link'>
                            <IconFont type="icon-icon-Stats" className='item-icon' />
                            <span>Stats</span>
                          </div>
                      </a>
                      <a href="https://cetus-1.gitbook.io/cetus-docs" className="item" target="_self" rel="noreferrer">
                          <div className='more_link'>
                            <IconFont type="icon-icon-Docs1" className='item-icon' />
                            <span>Docs</span>
                          </div>
                      </a>
                    </div>
                  </div>
                  {/* <a className='bridge' href="/">Bridge</a> */}
                  {/* <a href="https://app.cetus.zone/stats?source=bridge" target="_self" rel="noreferrer">Stats</a> */}
                </div>
                <div className="footer-box select">
                  <a href="https://www.portalbridge.com/#/transfer" target="_self" rel="noreferrer">
                    <img src={portal} alt="" />
                    <span>Switch to Portal Bridge</span>
                  </a>

                  <div className='exhibit'>
                    {list.map((item) => (
                      <a
                        key={item.name}
                        href={item.link}
                        target="_blank"
                        rel="noreferrer"
                        className={item.name === 'Docs' ? 'not_show':''}
                      >
                        {/* <img
                          style={{ width: 24, height: 24 }}
                          src={item.icon === "docs" ? docs : item.icon === "twitter" ? twitter : item.icon === "discord" ? discord : item.icon === "group" ? group : item.icon === "tgchannel" ? tgchannel : medium}
                          alt="" /> */}
                          <IconFont type={
                          item.icon === "twitter" ? 'icon-svg-twitter' :
                            item.icon === "discord" ? 'icon-svg-Discord' :
                              item.icon === "group" ? group :
                                item.icon === "tgchannel" ? 'icon-svg-tg' :
                                  'icon-svg-medium'} className='item-icon' />
                        {/* <span>{item.name}</span> */}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <WormholeBridge  config={classesChange() ? configSui : configAptos} />
      <div className={bodyClassChange()}></div>
    </div>

  );
}

export default App;
